import "./App.css";
import React from "react";
import { Step1 } from "./routes/step1";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/step1",
    element: <Step1 />,
  },
  {
    path: "/step2",
    element: null,
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
